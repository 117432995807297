import {
  format,
  addDays,
  getDay,
  isWithinInterval,
  isSameDay,
  parseISO,
  getDaysInMonth,
  endOfDay,
  startOfDay,
} from 'date-fns';

import { convertToDate } from './convertToDate';

const dayMap = {
  dom: 0,
  seg: 1,
  ter: 2,
  qua: 3,
  qui: 4,
  sex: 5,
  sab: 6,
};

// Cria helpers para evitar duplicação
function computeLastOccurrenceDate(recurrence, baseDate) {
  let lastOccurrenceDate = baseDate;
  for (let i = 1; i < (recurrence.ends_on_occurrences || 0); i++) {
    lastOccurrenceDate = addDays(
      lastOccurrenceDate,
      recurrence.repeat_each.value
    );
  }
  return lastOccurrenceDate;
}

function adjustWeeklyStartDate(recurrence, currentDate, end, dayMap) {
  while (
    !recurrence.repeat_each.repeat.includes(
      Object.keys(dayMap).find((d) => dayMap[d] === currentDate.getDay())
    ) &&
    currentDate <= end
  ) {
    currentDate = addDays(currentDate, 1);
  }
  return currentDate;
}

function shouldStopByOccurrences(occurrencesCount, recurrence) {
  return (
    recurrence.ends_on === 'occurrences' &&
    occurrencesCount > (recurrence.ends_on_occurrences || 0)
  );
}

export function calculateRecurrence({
  evts,
  startOfCurrentMonth: start,
  endOfCurrentMonth: end,
}) {
  const r = {};

  evts.forEach((ev) => {
    const baseDate = ev.created_at.toDate();
    const doctors = ev.doctors;
    const doctorIds = Object.keys(doctors);

    if (baseDate > endOfDay(end)) return;

    doctorIds.forEach((doctorId) => {
      const recurrence = doctors[doctorId].recurrence;
      const excludedDates = doctors[doctorId].excluded_dates || [];

      if (recurrence) {
        let currentDate = baseDate;
        let count = 0;
        const daysInMonth = getDaysInMonth(currentDate);

        if (recurrence.ends_on !== 'occurrences') {
          currentDate = start;
        }

        if (
          recurrence.ends_on === 'date' &&
          convertToDate(recurrence.ends_on_date) < start
        ) {
          return;
        }

        if (recurrence.ends_on === 'occurrences') {
          const lastOccurrenceDate = computeLastOccurrenceDate(
            recurrence,
            baseDate
          );

          if (lastOccurrenceDate < startOfDay(start)) {
            return;
          }
        }

        // Ajusta currentDate para a primeira ocorrência correta quando for repetição semanal
        if (
          recurrence.ends_on === 'never' &&
          recurrence.repeat_each.type === 'week' &&
          Array.isArray(recurrence.repeat_each.repeat)
        ) {
          currentDate = adjustWeeklyStartDate(
            recurrence,
            currentDate,
            end,
            dayMap
          );
        }

        let occurrencesCount = 0;

        while (true) {
          if (
            isWithinInterval(currentDate, {
              start: startOfDay(start),
              end: endOfDay(end),
            })
          ) {
            const formattedDate = format(currentDate, 'd-M-yyyy');
            if (
              !excludedDates.some((date) =>
                isSameDay(convertToDate(date), currentDate)
              )
            ) {
              if (!r[formattedDate]) r[formattedDate] = {};
              if (!r[formattedDate][doctorId]) r[formattedDate][doctorId] = [];

              // Verifica se já existe um evento com o mesmo grade_id
              const existingEventIndex = r[formattedDate][doctorId].findIndex(
                (existingEvent) => existingEvent.grade_id === ev.grade_id
              );

              if (existingEventIndex !== -1) {
                // Se o evento existente tem um created_at mais antigo, substitua-o
                if (
                  new Date(
                    r[formattedDate][doctorId][existingEventIndex].created_at
                  ) < new Date(ev.created_at)
                ) {
                  r[formattedDate][doctorId][existingEventIndex] = ev;
                }
              } else {
                r[formattedDate][doctorId].push(ev);
              }
            }
          }

          // Incrementar a data de acordo com a recorrência
          switch (recurrence.repeat_each.type) {
            case 'day':
              currentDate = addDays(currentDate, recurrence.repeat_each.value);
              break;
            case 'week': {
              const currentDay = getDay(currentDate);
              const nextDayIndex = recurrence.repeat_each.repeat.findIndex(
                (day) => dayMap[day] > currentDay
              );

              if (nextDayIndex !== -1) {
                currentDate = addDays(
                  currentDate,
                  dayMap[recurrence.repeat_each.repeat[nextDayIndex]] -
                  currentDay
                );
              } else {
                currentDate = addDays(
                  currentDate,
                  7 - currentDay + dayMap[recurrence.repeat_each.repeat[0]]
                );
              }
              break;
            }
            case 'month':
              if (recurrence.repeat_each.repeat_montly === 0) {
                const start = new Date(currentDate);
                currentDate = new Date(start);
                currentDate.setMonth(start.getMonth() + 1);
              } else if (recurrence.repeat_each.repeat_montly === 1) {
                const start = new Date(currentDate);
                const weekDay = start.getDay();
                const weekOfMonth = Math.floor((start.getDate() - 1) / 7) + 1;

                currentDate = new Date(start);
                currentDate.setMonth(start.getMonth() + 1);
                currentDate.setDate(1);

                let count = 0;
                while (count < weekOfMonth) {
                  if (currentDate.getDay() === weekDay) {
                    count++;
                  }
                  if (count < weekOfMonth) {
                    currentDate.setDate(currentDate.getDate() + 1);
                  }
                }
              }
              break;
            case 'year':
              currentDate = addDays(
                currentDate,
                recurrence.repeat_each.value * 365
              );
              break;
            default:
              break;
          }

          count += 1;

          if (
            recurrence.ends_on === 'date' &&
            currentDate >
            parseISO(convertToDate(recurrence.ends_on_date).toISOString())
          ) {
            break;
          }
          if (
            recurrence.ends_on === 'occurrences' &&
            occurrencesCount > recurrence.ends_on_occurrences
          ) {
            break;
          }
          if (recurrence.ends_on === 'never' && currentDate > end) {
            break;
          }

          if (count > daysInMonth) {
            break;
          }

          // Se ends_on for 'ocurrences', interrompe quando alcançar o limite
          if (recurrence.ends_on === 'occurrences') {
            occurrencesCount++;
            if (shouldStopByOccurrences(occurrencesCount, recurrence)) break;
          }
        }
      } else {
        const formattedDate = format(baseDate, 'd-M-yyyy');

        if (
          isWithinInterval(baseDate, {
            start: startOfDay(start),
            end: endOfDay(end),
          })
        ) {
          if (!r[formattedDate]) r[formattedDate] = {};
          if (!r[formattedDate][doctorId]) r[formattedDate][doctorId] = [];
          r[formattedDate][doctorId].push(ev);
        }
      }
    });
  });

  return r;
}
